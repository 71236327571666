













import { View } from 'client-website-ts-library/plugins';
import { API, Config } from 'client-website-ts-library/services';
import { Context, Office } from 'client-website-ts-library/types';
import { ContextItemType, CustomContextItem } from 'client-website-ts-library/types/Context';
import { Component, Mixins } from 'vue-property-decorator';

import Form from '../components/Forms/Form.vue';

@Component({
  components: {
    Form,
  },
})
export default class SalesApparaisal extends Mixins(View) {
  private formCtx: Context | null = null

  private office: Office | null = null

  mounted() {
    // Get the office id from the URL

    const officeId = this.$route.query.officeId as string | undefined;

    if (!officeId) {
      this.$router.push('/');
      return;
    }

    API.Offices.Get(officeId).then((office) => {
      this.office = office;
    });

    this.formCtx = {
      ClientWebsiteId: Config.Website.Id,
      Items: [
        {
          Type: ContextItemType.CustomData,
          Id: '',
          Key: 'OfficeId',
          Value: officeId,
        } as CustomContextItem,
      ],
    };
  }
}
